




























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ImportUiStateModule from '@/store/modules/ImportUiStateModule';

@Component
export default class AutoExportDetails extends Vue {
  get importResult() {
    return ImportUiStateModule.importResult;
  }
}
