var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"py-4",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"text-center pt-2 pb-4",attrs:{"cols":"10","offset":"1"}},[(_vm.importResult.NewAnnotationCount > 0)?_c('h2',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('TL_USER_IMPORT_COMPLETE'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.importResult.NewAnnotationCount == 0)?_c('h2',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('TL_USER_IMPORT_COMPLETE_NO_NEW'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.importResult.NewAnnotationCount == 0)?_c('div',[(!_vm.isChromeExtensionImport)?_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('TL_USER_IMPORT_WE_IMPORTED_ALL_OF_THE'))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.isChromeExtensionImport)?_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('TL_USER_IMPORT_COMPLETE_YOU_HAVE_ALREADY'))+"\n        ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.importResult.NewAnnotationCount > 0)?_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('TL_USER_IMPORT_YOU_SUCCESSFULLY_IMPORTED'))+"\n        "),_c('strong',[_vm._v(_vm._s(_vm._f("number")(_vm.importResult.NewAnnotationCount,'0,0')))]),_vm._v("\n        "+_vm._s(_vm.$t('TL_USER_IMPORT_NEW_ANNOTATIONS'))+"\n        "),_c('strong',[_vm._v(_vm._s(_vm._f("number")(_vm.importResult.ImportedBooks.length,'0,0')))]),_vm._v("\n        "+_vm._s(_vm.$t('TL_USER_IMPORT_BOOKS'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.importResult.IgnoredBookAnnotationCount > 0)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm._f("number")(_vm.importResult.IgnoredBookAnnotationCount,'0,0')))]),_vm._v("\n        "+_vm._s(_vm.$t('TL_USER_IMPORT_ANNOTATIONS_WERE_NOT_IMPORTED'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.importResult.IgnoredExistingAnnotationCount > 0)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm._f("number")(_vm.importResult.IgnoredExistingAnnotationCount,'0,0')))]),_vm._v("\n        "+_vm._s(_vm.$t(
            'TL_USER_IMPORT_ANNOTATIONS_WERE_NOT_IMPORTED_HAVE_PREVIOUSLY'
          ))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.importResult.IgnoredDeletedAnnotationCount > 0)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm._f("number")(_vm.importResult.IgnoredDeletedAnnotationCount,'0,0')))]),_vm._v("\n        "+_vm._s(_vm.$t(
            'TL_USER_IMPORT_ANNOTATIONS_WERE_NOT_IMPORTED_HAVE_PREVIOUSLY_DELETED'
          ))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.importResult.NewAnnotationCount > 0)?_c('b-button',{staticClass:"my-4",attrs:{"to":"/explorer","variant":"success","size":"lg"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_VIEW_IN_EXPLORER').toUpperCase()))]):_vm._e(),_vm._v(" "),(_vm.importResult.NewAnnotationCount == 0)?_c('b-button',{staticClass:"my-2",attrs:{"to":"/import","variant":"primary","size":"lg"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_TRY_AGAIN').toUpperCase()))]):_vm._e(),_vm._v(" "),(_vm.importResult.NewAnnotationCount > 0)?_c('div',{staticClass:"mt-4"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},_vm._l((_vm.importResult.ImportedBooks),function(importedBook){return _c('b-card',{key:importedBook.BookID,staticClass:"mb-4",attrs:{"title":importedBook.BookTitle}},[_c('b-card-text',[_vm._v("\n                "+_vm._s(_vm.$t('TL_USER_IMPORT_NEW_HIGHLIGHTS', {
                    newhighlights: _vm.number(
                      importedBook.TotalNewAnnotationsCount
                    ),
                  }))+"\n              ")]),_vm._v(" "),_c('b-button',{attrs:{"to":("/explorer/books/" + (importedBook.BookID)),"variant":"primary","size":"sm"}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_VIEW')))])],1)}),1)],1)],1):_vm._e(),_vm._v(" "),(
          _vm.importResult.NewAnnotationCount > 0 &&
          _vm.importResult.AutoExportDetails.length > 0
        )?_c('autoexportdetails'):_vm._e(),_vm._v(" "),(!_vm.isChromeExtensionImport)?_c('usechromeextensionnexttime'):_c('chromeextensionreviewrequest')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }