
































































































































import { Component, Vue } from 'vue-property-decorator';
import ImportUiStateModule from '@/store/modules/ImportUiStateModule';

import autoexportdetails from '@/pages/import/components/AutoExportDetails.vue';
import usechromeextensionnexttime from '@/pages/import/components/UseChromeExtensionNextTime.vue';
import chromeextensionreviewrequest from '@/pages/import/components/ChromeExtensionReviewRequest.vue';

@Component({
  components: {
    autoexportdetails,
    chromeextensionreviewrequest,
    usechromeextensionnexttime,
  },
})
export default class ImportCompletePage extends Vue {
  //code smell
  number(value: any) {
    return this.$options!.filters!.number(value);
  }

  get importResult() {
    return ImportUiStateModule.importResult;
  }
  get isChromeExtensionImport() {
    return ImportUiStateModule.importDefinition!
      .isChromeExtensionImport;
  }
}
